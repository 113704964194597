import * as React from "react";
import { useWindowSize } from "../../hooks/window-size";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import ContactDetails from "../ContactDetails/ContactDetails";
import MemberCard from "../MemberCard/MemberCard";
import ContactForm from "../ContactPageForm/InputData";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Form.scss";
const Connect = () => {
  const [windowWidth] = useWindowSize();
  return (
    <>
      <section className="contact-wrap connect ">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs-wrap">
                <div className="content-wrap">
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">About</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Careers</Breadcrumb.Item>
                    <Breadcrumb.Item>
                      
                      Sales Account Manager - Property
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      
                      Apply for this job
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <Col className="contact-title">
                <h1>Apply for this job </h1>
                <p>
                  Etiam cursus augue ac maximus maximus. Phasellus lobortis ante
                  at erat fringilla, viverra pulvinar augue dictum quisque
                  molestie lacinia lectus.
                </p>
              </Col>
            </AnimationOnScroll>
          </Row>
          <Row>
            <Col lg={8} className="form-field-wrapper">
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
                <ContactForm />
              </AnimationOnScroll>
            </Col>
            <Col lg={4} className="card-field-wrapper">
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                <MemberCard />
                <ContactDetails />
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Connect;
